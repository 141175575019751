import { NextPage } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const HomePage: NextPage = () => <p>You probably should not be here</p>;

export default HomePage;

/**
 * SSprops for the page
 *
 * @param param0 The options for the page
 * @param param0.locale The language locale
 * @returns props to be rendered
 */
export async function getServerSideProps({ locale }: { locale: string }) {
	return { props: { ...(await serverSideTranslations(locale, ["common"])) } };
}
